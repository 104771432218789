// eslint-disable-next-line wix-custom-rules/wixstores-file-names
import {SubscriptionFrequency} from '@wix/wixstores-client-storefront-sdk/dist/es/src/graphql/queries-schema';

export const shorten = (text: string, maxLength: number, suffix: string = '') => {
  if (text.length <= maxLength) {
    return text;
  }
  const trimmedText = text.substr(0, maxLength - suffix.length);
  return `${trimmedText}${suffix}`;
};

export const getSubscriptionFrequencyTranslationKey = (interval: number, frequency: SubscriptionFrequency) => {
  if (interval === 1) {
    switch (frequency) {
      case 'WEEK':
        return 'thank_you_page_plan_frequency_description_week_singular_label';
      case 'MONTH':
        return 'thank_you_page_plan_frequency_description_month_singular_label';
      case 'YEAR':
        return 'thank_you_page_plan_frequency_description_year_singular_label';
    }
  } else if (interval === 2) {
    switch (frequency) {
      case 'WEEK':
        return 'thank_you_page_plan_frequency_description_2_weeks_label';
      case 'MONTH':
        return 'thank_you_page_plan_frequency_description_2_months_label';
      case 'YEAR':
        return 'thank_you_page_plan_frequency_description_2_years_label';
    }
  } else {
    switch (frequency) {
      case 'WEEK':
        return 'thank_you_page_plan_frequency_description_weeks_plural_label';
      case 'MONTH':
        return 'thank_you_page_plan_frequency_description_months_plural_label';
      case 'YEAR':
        return 'thank_you_page_plan_frequency_description_years_plural_label';
    }
  }
};
